import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import SpeedImg from "../../assets/images/translation/speed.webp"
import SpeedMImg from "../../assets/images/translation/speed-m.webp"
import TranslationServiceMImg from "../../assets/images/translation/translation-service-m.webp"
import BuildInTranslationMImg from "../../assets/images/translation/build-in-translation-m.webp"
import VarietyOfLanguageMImg from "../../assets/images/translation/variety-of-language-m.webp"

const Translation = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Translation | BlueMail App"
        postDescription="BlueMail lets you translate a back-and-forth any email conversation with a tap of a button."
        postImage="/img/OG/og_image-translation-service.png"
        postURL="features-functions/translation"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid translation-service w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>Translation</span> Service
                </h1>
                <p>
                  Most of us are used to writing emails in one language as it
                  was the only option available. And if you ever wanted to write
                  email in a different language, you had to write it and use a
                  separate translation service, copy-paste to your email and
                  send it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={TranslationServiceMImg} alt="Translation Service" />
            </div>
          </div>
        </div>
        <div className="container-fluid build-in-translation w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>Built-in</span> Translation
                </h2>
                <p>
                  BlueMail lets you translate a back-and-forth any email
                  conversation with a tap of a button.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={BuildInTranslationMImg} alt="Built-in Translation" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>Speed</span>
                </h2>
                <p>
                  Our translation service makes translating content into
                  different languages quicker than ever before. It can be
                  invaluable when an email needs translating and speed is of the
                  essence. The speediness of machine translation allows you to
                  compose an email in a different language instantly.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img className="d-992" src={SpeedImg} alt="Speed" />
              <img
                className="w-991 mt-3 mb-5"
                src={SpeedMImg}
                alt="Speed mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid variety-of-language w-707 r mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Wide Variety Of <span>Languages</span>
                </h2>
                <p>
                  The BlueMail Translation service allows you to translate your
                  content into a wide variety of languages, more than 100
                  languages - almost any major language.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img
                src={VarietyOfLanguageMImg}
                alt="Wide Variety Of Languages"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Translation

export const query = graphql`
  query TranslationPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
